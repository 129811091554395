import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';


const config = {
  apiKey: "AIzaSyCWm0ZmQfgmqEqGuFFQqfkewU58d2V-14c",
    authDomain: "custom-app-project-test.firebaseapp.com",
    databaseURL: "https://custom-app-project-test.firebaseio.com/",
    projectId: "custom-app-project-test",
    storageBucket: "custom-app-project-test.appspot.com",
    messagingSenderId: "981995534626"
}; 

firebase.initializeApp(config);

export const database = firebase.database();
export const auth = firebase.auth();
export const storage = firebase.storage();


const key = 'firebase:authUser:AIzaSyCWm0ZmQfgmqEqGuFFQqfkewU58d2V-14c:[DEFAULT]';
const localUser = JSON.parse(window.localStorage.getItem(key))


export const USER = async ()=> {
  const { currentUser } = await auth;
  return currentUser || localUser;
}

export const isAuth = ()=> {
  return auth.currentUser || localUser;
}
