import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import rootReducer from 'reducers';
import 'index.css';
import Audit from 'components/audit';
import SignIn from 'components/signin';
import PrivateRoute from 'components/auth';
import * as serviceWorker from 'serviceWorker';

import { verifyAuth } from './actions';

export function configureStore(initialState) {
    const store = createStore(
        rootReducer,
        initialState,
        compose (
            applyMiddleware(thunk),
            window.devToolsExtension ? window.devToolsExtension() : f => f
        )
    );
    
    store.dispatch(verifyAuth())

    return store;
}

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <PrivateRoute exact path='/audit' component={Audit} />
                <Route path='/' component={SignIn} />
            </Switch>
        </BrowserRouter>
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
