import { map, reverse, values as _values } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { database } from 'firebase_config';
import SpeechRecognition from 'react-speech-recognition';
import {
    TextField,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemSecondaryAction,
    Dialog
} from '@material-ui/core';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Help from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/core/styles';

import * as actions from 'actions/audit_actions';

const assets = database.ref('serialized_assets/assets')

const useStyles = makeStyles({
  card: {
    maxWidth: 500,
    margin: '60px auto',
    position: 'relative'
  },
  input: {
      width: 380
  }
});

const Audit = ({
    transcript,
    resetTranscript,
    startListening,
    stopListening,
    listening,
    browserSupportsSpeechRecognition,
    countItem,
    fetchCounted,
    deleteCounted,
    counted
}) => {
    const classes = useStyles();
    const [asset, setAsset] = useState('');
    const [description, setDescription] = useState('');
    const [help, setHelp] = useState(false);
    const reversed = reverse(_values(counted));
    useEffect(fetchCounted, []);
    useEffect(() => {
        const count = transcript.toLowerCase().search('count') > -1;
        const reset = transcript.toLowerCase().search('reset') > -1;
        const stop = transcript.toLowerCase().search('stop listening') > -1;
        const undo = transcript.toLowerCase().search('undo last') > -1;
        if (stop) {
            stopListening();
            resetTranscript();
        }
        if (reset) resetTranscript();
        if (undo) {
            deleteCounted(reversed[0].id);
            setAsset('')
            resetTranscript();
        }
        if (count) {
            handleSubmit(asset.replace(/\D/g, ""));
            resetTranscript();
        } else {
            setAsset(transcript.replace(/ /g, '') || '')
        }}, [transcript])
    useEffect(() => {
        if (asset.length > 2) {
            assets.child(asset.replace(/ /g, '')).child('description').once('value', snapshot => {
                if (snapshot.val()) setDescription(snapshot.val());
                else setDescription('');
            })
        } else {
            setDescription('');
        }
    }, [asset])
    const handleSubmit = (value) => {
        if (description) countItem(asset, description);
        if (asset) {
            resetTranscript();
            setAsset('');
            setDescription();
        }
        
    }
    return (
        <div>
            <Card className={classes.card}>
                <CardHeader
                    title="Enter Asset Number"
                />
                <IconButton style={{position: 'absolute', right: 8, top: 8}} onClick={() => setHelp(true)}>
                
                <Help/>
                </IconButton>
                <form onSubmit={e => {
                    e.preventDefault();
                    handleSubmit();
                }}>
                    <CardContent>
                        <TextField
                            variant="outlined"
                            className={classes.input}
                            value={asset}
                            label="Asset"
                            onChange={e => setAsset(e.target.value)}
                        />
                        <Typography variant="subtitle1">{description}</Typography>
                        <Typography variant="subtitle1">{transcript}</Typography>
                        
                    </CardContent>
                    <CardActions>
                        <Button onClick={startListening}>Listen</Button>
                        <Button onClick={stopListening}>Stop Listening</Button>
                        <Button type="submit">Submit</Button>
                    </CardActions>
                </form>
            </Card>
            {console.log({counted})}
            <List>
                {map(reversed, (item) => {
                    return <ListItem key={item.id}>
                    {item.asset}: {item.description}
                    <ListItemSecondaryAction>
                        <IconButton onClick={
                            () => deleteCounted(item.id)
                        }>
                            <DeleteForever />
                        </IconButton>
                    </ListItemSecondaryAction>
                    </ListItem>
                })}
            </List>
            <Dialog
                open={help}
                onClose={() => setHelp(false)}
            >
                Commands: 
                <ul>
                    <li>"COUNT" - Submits the current asset (only counts if a description is found)</li>
                    <li>"RESET" - resets the form without submitting</li>
                    <li>"UNDO LAST" - deletes the last counted item</li>
                    <li>"STOP LISTENING" - stops the microphone listener</li>
                </ul>
            </Dialog>
        </div>
    );
};

const options = {
  autoStart: false
}

const mapStateToProps = ({counted}) => ({
    counted
})

export default connect(mapStateToProps, actions)(SpeechRecognition(options)(Audit));