import { database, USER } from 'firebase_config';

export const FETCH_COUNTED = "FETCH_COUNTED";

const audited = database.ref('serialized_assets/audited')

export function countItem(asset, description) {
    const id = audited.push().key
    return (d, getState) => audited.child(id).set({
        asset,
        description,
        id,
        timestamp: Date.now(),
        user: getState().authUser.email})
}
export function deleteCounted(id) {
    return () => audited.child(id).remove()
}

export function fetchCounted() {
    return (dispatch) => {
        USER().then((user) => {
            audited.orderByChild('user').equalTo(user.email).limitToLast(30).on('value', snapshot => {
            const items = snapshot.val();
            dispatch({
                type: FETCH_COUNTED,
                payload:  items ? items : {}
            })
        })
        })
    }
}